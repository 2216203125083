<template>
  <el-dialog v-dialogDrag title="新增应用菜单" :visible.sync="visible" width="600px">
    <el-form label-width="100px">
      <el-form-item label="所属模块">
        <el-input></el-input>
      </el-form-item>
      <el-form-item label="菜单名称">
        <el-input></el-input>
      </el-form-item>
      
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="$emit('update:show',false)">取 消</el-button>
      <el-button size="small" type="primary"  @click="$emit('update:show',false)">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("update:show", val);
      },
    },
  },
  watch: {},
  created() {
    console.log(this.show, this.visible);
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.editor {
  width: 100%;
  height: 350px;
}
</style>